@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,400;9..40,500&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
html, body, #root {
  height: 100%;
  background: #030014 !important;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
input, button, textarea, select {
  font: inherit;
}
input:-webkit-autofill{
  -webkit-text-fill-color: #efedfdb3 !important;
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
#root, #__next {
  isolation: isolate;
}

.gradientBackground {
  background: linear-gradient(333deg, rgba(33,17,137,1) 0%, rgba(0,0,0,1) 31%, rgba(0,0,0,1) 63%, rgba(70,34,101,1) 89%);
}

.gradientBackground2 {
  background: linear-gradient(333deg, rgba(33,17,137,1) 0%, rgba(3, 0, 20, 1) 50%, rgba(3, 0, 20, 1) 50%, rgba(70,34,101,1) 90%);
}


/* React Quill Styles */

.quill > .ql-container > .ql-editor {
  font-size: 20px;
  color: #efedfdb3
}

.quill > .ql-container > .ql-editor > h1 {
  font-size: 34px;
}

.quill > .ql-container > .ql-editor > h2 {
  font-size: 28px;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  font-size: 20px;
  color: #8f8ca199;
}

.quill > .ql-container > .ql-editor a::before {
  top: 30px;
}

.customQuill > .ql-toolbar {
  border: 1px solid #ffffff1a;
  border-radius: 8px;
}

.customQuill > .ql-toolbar > .ql-formats {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4px;
}

.customQuill > .ql-toolbar > .ql-formats > * {
  width: 40px;
  height: 40px;
  @media only screen and (max-width: 640px) {
    width: 20px;
    height: 20px;
  }
}

.customQuill > .ql-container {
  display: none;
}

.customQuill > .ql-toolbar .ql-picker-label {
  display: flex;
  align-items: center;
}

.customQuill > .ql-toolbar .ql-picker-label > svg {
  width: 40px;
  height: 40px;
  @media only screen and (max-width: 640px) {
    width: 20px;
    height: 20px;
  }
}

.customQuill > .ql-toolbar .ql-stroke {
  fill: none;
  stroke: #bf97ff;
}

.customQuill > .ql-toolbar .ql-fill {
  fill: #bf97ff;
  stroke: none;
}

.customQuill > .ql-toolbar .ql-picker {
  color: #bf97ff;
}


/* NextUI Styles */

.next-accordion-item section {
  overflow-y: visible !important
}


/* Custom Styles */

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.hidden-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.logo {
  background-image: radial-gradient(circle, rgba(3,0,20,1) 10%, rgba(191,151,255,0.50) 20%, rgba(3,0,20,1) 40%, rgba(191,151,255,0.50) 50%, rgba(3,0,20,1) 60%, rgba(191,151,255,0.50) 70%, rgba(3,0,20,1) 90%)
}

.preventScroll .cm-scroller {
  overflow-x: hidden !important
}